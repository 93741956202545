@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Telugu:wght@400&family=Roboto+Slab:wght@700&display=swap");

html {
  @apply text-dark-purple;
  @apply bg-white-slate;
  /**
  @apply dark:bg-gray-800;
  @apply dark:text-white-slate;
  **/
}

@keyframes pulse-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* @keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
} */

.bg-pulse-gradient {
  background: linear-gradient(
    -45deg,
    #ff758c,
    #ff7eb3,
    #8a74ff,
    #c5c5ff,
    #5ee8c5,
    #36d1dc
  );
  background-size: 400% 400%;
  animation: pulse-gradient 3s ease-in-out infinite;
}

.bouncing-text {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.colorful {
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-pulse-gradient {
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(
      -45deg,
      #ff758c,
      #ff7eb3,
      #8a74ff,
      #c5c5ff,
      #5ee8c5,
      #36d1dc
    )
    10;
  animation: pulse-gradient 3s ease-in-out infinite;
}
